
/** @jsx jsx */

import MySEO from '../components/seo'

import Link from '../components/Link'
import {Container,Box,jsx,Flex,Text,Heading} from 'theme-ui'
import Layout from '../components/layout'

export default function Kontakt(){
    return(
        <Layout>
       <Flex>

<Container sx={{m:1}}>

<MySEO title="Kontakt" description="Kontaktieren Sie uns" />

<Box sx={{p:0}}>

<Heading sx={{color:'text',fontWeight:"500"}} as="h1">Kontakt</Heading>

<Heading sx={{color:'text'}}>Verein Kulturnetz <br/>Oberes Mittelrheintal e.V.</Heading>

<br/>

<Text>
Katrin Gloggengießer<br/>   
Langstr. 48, 55422 Bacharach <br/><br/>

Email: kontakt@kulturnetz-oberes-mittelrheintal.org
</Text>



<br/>

<Link sx={{color:`text`}} to='/impressum' > &rarr;  Impressum </Link>

</Box>

</Container>
</Flex>
</Layout>
    )
}
